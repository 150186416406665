.footer {
  background-color: #1f103e;
}

.footer-padding {
    padding-left: 24px !important;;
}

.footer-headers {
  font-weight: bold;
  line-height: 24.7px;
  color: #955aff;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  padding-top: 41px;
}

.footer-links {
  list-style: none;
  padding-left: 0;
  padding-bottom: 60px;
  width: 173px;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.54;
  letter-spacing: 0.5px;
  color: #ffffff;
}

.logo-image-footer {
  width: 160.6px;
  height: 46.6px;
}

.all-rights {
  height: 85px;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.54;
  letter-spacing: normal;
  color: #955aff;
}

.panda-link {
  color: #ffffff;
  line-height: 1.54;
}

.panda-link:hover {
  color: #d3cae8;
  text-decoration: none;
}
