.brands-text-center{
    text-align: center; 
    margin: 10px; 
}

.brands-numbers{
    color: #955aff; 
    font-size: 24px; 
    font-weight: 800;
    font-family: 'Roboto', sans-serif; 
    text-align: center; 
}

.hidden{
    visibility: hidden;
}

.brand-form-card{
    margin-top: 20px; 
    padding: 15px; 
    margin-bottom: 40px; 
}

input.brand-input{
    width: 100%; 
}

.max-width{
    width: 100%; 
}

.brand-form{
    margin-bottom: 0px; 
    padding-bottom: 0px ;
}

.brand-card{
    margin-top: 20px; 
}