
.center-purchase-buttons{
  margin: 0 auto; 
}

.cancel-purchase-modal{
  border-radius: 4px; 
  width: 142px; 
  height: 48px; 
  background-color: #d8d8d8; 
  color: white; 
  cursor: pointer; 
  margin: 8px; 
}


.buy-purchase-modal{
  border-radius: 4px; 
  width: 142px; 
  height: 48px; 
  background-color: #7d5cbe; 
  color: white; 
  cursor: pointer; 
  margin: 8px; 
}

  .Checkout {
    margin: 0 auto;
    max-width: 800px;
    box-sizing: border-box;
    padding: 0 5px;
  }

  label {
    color: #6b7c93;
    font-weight: 300;
    letter-spacing: 0.025em;
  }

  button {
    white-space: nowrap;
    border: 0;
    outline: 0;
    display: inline-block;
    height: 40px;
    line-height: 40px;
    padding: 0 14px;
    color: #fff;
    border-radius: 4px;
    font-size: 15px;
    font-weight: 600;
    letter-spacing: 0.025em;
    background-color: #6772e5;
    text-decoration: none;
    -webkit-transition: all 150ms ease;
    transition: all 150ms ease;
    margin-top: 10px;
  }

  form {
    margin-bottom: 40px;
    padding-bottom: 40px;
  }

  button:hover {
    color: #fff;
    cursor: pointer;
    background-color: #7795f8;
    transform: translateY(-1px);
    box-shadow: 0 7px 14px rgba(50, 50, 93, .10), 0 3px 6px rgba(0, 0, 0, .08);
  }

  input,
  .StripeElement {
    display: block;
    max-width: 1000px !important;
    padding: 10px 14px;
    font-size: 1em;
    font-family: 'Roboto, sans-serif';
    border: 0;
    outline: 0;
    border-radius: 4px;
    background: white;
    border: 1px solid #cccccc;
  }

  input::placeholder {
    color: #aab7c4;
  }

  input:focus,
  .StripeElement--focus {
    -webkit-transition: all 150ms ease;
    transition: all 150ms ease;
  }

  .StripeElement.StripeElement--empty{
    width: 400px; 
  }
  .StripeElement.StripeElement--invalid{
    width: 400px; 
  }
  .StripeElment.StripeElement--complete{
    width: 400px; 
  }
  .StripeElement{
    width: 400px !important; 
  }

  .StripeElement.IdealBankElement,
  .StripeElement.PaymentRequestButton {
    padding: 0;
  }