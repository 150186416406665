html {
  height: 100%;
  margin: auto;
  background-image: linear-gradient(90deg, #7d5cbe,  #4a377c);
}
body {
  min-height: 100%;
  font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
}

#root {
  min-height: 100%;
}

.nav-link{
  font-family: HelveticaNeue;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #d3cae8 !important;
}

.navbar-light .navbar-nav .nav-link {
  color:#d3cae8 !important;
}
.navbar-light .navbar-nav .nav-link:hover {
  cursor: pointer;
}

/* * {
    border: 1px solid #f00 !important;
  } */