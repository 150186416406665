
@media (min-width: 320px) and (max-width: 480px) {
  .Landing-subtitle {
    padding-top: 24px;
    padding-left: 24px;
    padding-right: 24px;
    font-family: Roboto, sans-serif;
    font-size: 36px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.28;
    letter-spacing: normal;
    background-image: linear-gradient(90deg, #4a377c, #7d5cbe);
    color: white;
  }
  .subtext{
    line-height:1.5em;
    font-family: Roboto, sans-serif; 
    padding-top: 15px; 
    font-weight: 300; 
    font-size: 1.25rem;
    font-family: Roboto, sans-serif;
  }
  p {
    margin: 0px !important;
  }

  .Landing-header {
    background-image: linear-gradient(90deg, #4a377c, #7d5cbe);
    text-align: center;
    overflow: hidden;
  }

  .Landing-signup {
    padding-top: 40px;
  }

  .Landing-signup-cta {
    background-color: white;
    color: #7d5cbe;
    font-family: Roboto, sans-serif;
    font-size: 24px;
    font-weight: 500;
    height: 48px;
    width: 145px;
    border-radius: 4px;
    box-shadow: 0 8px 10px 0 rgba(0, 0, 0, 0.2);
    margin-top: 20px;
    text-transform: initial;
  }

  .Landing-header-image {
    height: 100%;
    width: 100%;
    padding-left: 18px;
    padding-top: 40px;
    float: right;
  }

  .Landing-form {
      margin-bottom: 0px !important;
      padding-bottom: 0px !important;
      border: 0px solid black !important;
  }

  .Landing-confirm {
    float: right;
    background-color: #7d5cbe;
    color: white;
    height:48px;
    border-radius:4px;
  }

  .Landing-email-div {
    overflow: hidden;
    padding-right: 0.5em;
    height:48px;
  }

  .Landing-email-input {
    float: left;
    width: 100%;
  }

  .Landing-email-input::-moz-placeholder  {
    font-family: Roboto, sans-serif;
    font-size: 14px;
    padding-top: 34px !important;
  }

  .Landing-why {
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 46px;
    padding-bottom: 40px;
    font-family: Roboto, sans-serif;
    font-size: 32px;
    font-weight: bold;
    line-height: 1.84;
    text-align: center
  }

  .Landing-features {
    padding-left: 12px;
    padding-right: 12px;
    padding-bottom: 47px;
  }

  .Landing-feature-image-door {
    display: block;
    margin: 0 auto;
    width: 110px;
    height: 90px;
  }

  .Landing-feature-image-safe {
    display: block;
    margin: 0 auto;
    width: 105px;
    height: 88px;
  }

  .Landing-feature-image-list {
    display: block;
    margin: 0 auto;
    width: 90px;
    height: 90px;
  }

  .Landing-pitch {
    font-family: Roboto, sans-serif;
    font-size: 19px;
    font-weight: 700;
    margin-top: 8px !important;
    margin-bottom: 8px !important;
    width: 290px;
    height: 26px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
  }

  .Landing-pitch-body {
    width: 290px;
    height: 133px;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.49;
    letter-spacing: normal;
    color: #000000;
  }

  .Landing-signoff {
    padding-left: 24px;
    padding-right: 24px;
    background-color: #bc91ff;
    color: white;
    font-family: Roboto, sans-serif;
    font-size: 23px;
    font-weight: 500;
    text-align: center;
  }

  .Landing-video {
    display: none;
    height: 554.6px;
    width: 100%;
  }

  .Landing-last-email {
    padding-left: 24px;
    padding-right: 24px;
    padding-bottom: 53px;
    text-align: center;
    background-color: #bc91ff;
  }

  .Landing-val-props-image-small {
    width: 100%;
    margin-bottom: 50px;
    mix-blend-mode: multiply;
  }
}

/* desktop */
@media (min-width: 720px) {
  .Landing-subtitle {
    padding-left: 10%;
    padding-right: 258px;
    font-family: Roboto, sans-serif;
    font-size: 4.5vw;
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.28;
    letter-spacing: normal;
    background-image: linear-gradient(90deg, #4a377c, #7d5cbe);
    color: white;
  }
  .subtext{
    line-height:1.5em;
    font-family: Roboto, sans-serif; 
    padding-top: 15px;
    padding-right: 40%;
    font-weight: 300; 
    font-size: 2rem;
    font-family: Roboto, sans-serif;
  }
  body{
    width:100%;
    overflow:auto;
  }

  p {
    margin: 0px !important;
  }

  .Landing-signup-cta {
    background-color: white;
    color: #7d5cbe;
    font-family: Roboto, sans-serif;
    font-size: 24px;
    font-weight: 500;
    height: 64px;
    width: 178px;
    border-radius: 4px;
    box-shadow: 0 8px 10px 0 rgba(0, 0, 0, 0.2);
    margin-top: 20px;
    text-transform: initial;
  }

  .Landing-header {
    background-image: linear-gradient(90deg, #4a377c, #7d5cbe);
    text-align: center;
    overflow: hidden;
  }

  .Landing-header-image {
    height: 301px;
    width: 667px;
    float: right;
  }

  .Landing-email-row {
    clear: right;
    padding-left: 60px;
    padding-right: 60px;
    padding-top: 43px;
    background-color: #261f39;
    color: white;
    font-size: 14px !important;
  }

  .Landing-confirm {
    float: right;
    background-color: #7d5cbe;
    color: white;
    height:48px;
    border-radius:4px;
  }

  .Landing-form {
    margin-bottom: 0px !important;
    padding-bottom: 11px !important;
    padding-top: 18px;
    padding-left:16px;
    border: 0px solid black !important;
    width: 626px;
}

  .Landing-email-div {
    overflow: hidden;
    padding-right: 0.5em;
    height:49px; 
  }

  .Landing-email-input {
    width: 100%;
    height:48px;
  }

  .Landing-email-counter {
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-weight: 500;
    color: #a1a1a1;
    padding-bottom: 40px;
  }

  .Landing-why {
    padding-left: 60px;
    padding-right: 60px;
    padding-top: 46px;
    font-family: Roboto, sans-serif;
    font-size: 45px;
    font-weight: 100;
    line-height: 1.84;
    text-align: center
  }

  .Landing-bold {
    font-weight: bold;
  }

  .Landing-features {
    padding-bottom: 47px;
    margin-top: 28px;
    margin-left: 20% !important;
    margin-right: 20% !important;
  }

  .Landing-feature-image-door {
    margin: 0 auto;
    width: 110px;
    height: 90px;
  }

  .Landing-feature-image-safe {
    margin: 0 auto;
    width: 105px;
    height: 88px;
  }

  .Landing-feature-image-list {
    margin: 0 auto;
    width: 90px;
    height: 90px;
  }
  
  .Landing-val-props-image-large {
    display: block;
    margin: 0 auto;
    margin-bottom: 40px;
    width:700px;
    height: 378px;
  }

  .Landing-pitch {
    font-family: Roboto, sans-serif;
    font-size: 19px;
    font-weight: bold;
    margin-top: 8px !important;
    margin-bottom: 22px !important;
    text-align: left;
    height: 26px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
    
  }

  .Landing-pitch-body {
    text-align: left;
    height: 133px;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.49;
    letter-spacing: normal;
    color: #000000;
  }

  .Landing-signoff {
    padding-left: 24px;
    padding-right: 24px;
    background-color: #bc91ff;
    color: white;
    font-family: Roboto, sans-serif;
    font-size: 23px;
    font-weight: 500;
    text-align: center;
  }

  .Landing-video {
    height: 554.6px;
    width: 100%;
  }

  .Landing-last-email {
    padding-left: 24px;
    padding-right: 24px;
    padding-bottom: 53px;
    text-align: center;
    background-color: #bc91ff;
  }

  .Landing-form.bottom-form{
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}

.alert {
  margin-bottom: 0px !important;
}