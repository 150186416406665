.close-modal{
    position: absolute;
    right: 15px;
    top: 10px;
    cursor: pointer;
    color: grey; 
}

.modal-header-title{
    text-align: center;
}

.modal-content{
    background: #f3f5f6 !important; 
}

.modal-header{
    text-align: center !important; 
    display: block !important; 
}

.modal-profile-picture-rounded{
    border-radius: 50%; 
    width: 24px; 
    height: 24px; 
    border: 2px solid #fff; 
    box-shadow: 0 5px 10px 0 rgba(17, 81, 92, 0.3);
}

.sign-in-button{
    border-radius: 4px; 
    width: 142px; 
    height: 48px; 
    background-color: #7d5cbe; 
    color: white; 
    cursor: pointer; 
    margin: 8px; 
  }