
@media (min-width: 320px) and (max-width: 480px) {
    .Profile-PandaLink-Head {
        margin-top: 20px !important;
    }
}
@media (min-width: 720px) {
    .Profile-PandaLink-Head {
        margin-top: 30px !important;
    }
    .Profile-Streamlab-Button {
      margin-top: 10px !important;
    }
}
.Profile-Image {
  width: 90px;
  margin-top: 8px;
  border: 5px solid white;
  border-radius: 500px;
  -webkit-border-radius: 500px;
  -moz-border-radius: 500px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
}

.Profile-fontawesome {
  color: #9f6d9f;
  font-size: 20px;
}

.Profile-PandaLink-Head {
    font-family: Roboto, sans-serif;
    font-weight: bold;
    font-size: 16px;
}

.Profile-Streamlab-Button {
  font-family: Roboto, sans-serif;
  font-weight: bold;
  font-size: 16px;
}

.Profile-PandaLink-Desc {
    font-family: Roboto, sans-serif;
    font-size: 12px;
}
.Profile-PandaLink-Box {
    margin-top: 10px;
    border-radius: 6px;
}
.Profile-Streamlabs-Link {
  width: 100%;
  height: 48px;
  border-radius: 4px;
  background-color: #32C3A2 !important;
  box-shadow: 0px 0px 0px 0px !important;
  border: 0px !important;
  margin-bottom: 30px;
  margin-top: 14px;
  padding: 0 0 0 0 !important;
}
.Profile-Community-ID-Title {
    font-family: Roboto, sans-serif;
    font-size: 16px;
    font-weight: bold;
    margin-top: 30px !important;
}
.Profile-Community-ID-Desc {
    font-family: Roboto, sans-serif;
    font-size: 12px;
}
.Profile-Bio-Title {
    font-family: Roboto, sans-serif;
    font-size: 16px;
    font-weight: bold;
    margin-top: 28px !important;
}
.Profile-Bio-Div {
    padding-top: 14px;
    padding-bottom: 14px;
    font-family: Roboto, sans-serif;
    font-size: 14px;
}
.Profile-Nav-Tabs {
    margin-top: 50px;
}
.Profile-Nav-Tabs:hover {
    cursor: pointer;
}
.Profile-title {
    font-size: 28px;
    font-family: Roboto, sans-serif;
    margin-top: 8px;
}
.Profile-Bio-Div-Fan {
    font-family: Roboto, sans-serif;
    font-size: 14px;
    margin-top: 37px;
}
.Profile-title-username {
    color: #4a377c;
    font-weight: bold;
}
.Profile-Account-Detail {
    font-family: Roboto, sans-serif;
    font-size: 14px;
    padding-bottom: 3px;
}
.Profile-Account-URL {
  font-family: Roboto, sans-serif;
  font-size: 14px;
  color: #7d5cbe;
}
img.profile-picture{
    max-width: 65px;
    border-radius: 50%;
    margin-bottom: 20px;
}
.profile-area {
    float: right;
}
.panel-body h3 {
    margin-top: 0;
}

#wishpanda-link{
    background-color: #efecef;
    border: 0px; 
    width: 100%;
    height: 50px;
    font-size: 16px;
    font-weight: 300;
    border-radius: 6px;
    -webkit-box-shadow: 0px 6px 10px 0.5px rgba(0,0,0,0.15);
    -moz-box-shadow: 0px 6px 10px 0.5px rgba(0,0,0,0.15);
    box-shadow: 0px 0px 0px 0px;
    margin-top: 8px;
}

#wishpanda-link > span {
    color: #7d5cbe;
    background-repeat:no-repeat;
    border: none;
    cursor:pointer;
    overflow: hidden;  
    background-color: #efecef;
    width: 100%;
    font-family: Roboto, sans-serif;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.69;
    letter-spacing: normal;
    text-align: center;
    color: #7d5cbe;
    display: block;
    text-overflow: ellipsis;
    text-transform: lowercase;
}

.Profile-badge-button {
  width: 100%;
  height: 48px;
  border-radius: 4px;
  background-color: #7d5cbe !important;
  box-shadow: 0px 0px 0px 0px !important;
  border: 0px !important;
  margin-bottom: 30px;
  margin-top: 14px;
}

.Profile-Address-Row {
  margin-top: 24px;
}
.Profile-Address-Label {
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: .25rem;
  color: #2b2b2b;
}
.Profile-Address-Input {
  box-shadow: none;
}
.Profile-Address-Input::placeholder {
  color: #c3c3c3 !important;
}
.saveChangesButton{
  width: 100%;
  height: 48px;
  border-radius: 4px;
  background-color: #7d5cbe !important;
  box-shadow: 0px 0px 0px 0px !important;
  border: 0px !important;
  margin-bottom: 80px;
}

.Profile-Nav-Tabs>nav-item>.nav-link {
  background-color: rgba(125, 92, 190, 0.3) !important;
  color: white !important;;
}

.active.nav-link {
  background-color: #7d5cbe !important;
  color: white !important;
}

.calendar {
    font-size:16px;
}

.panda-link-icon{
    font-size:15px;
}