.Wishlist-Nav-Tabs {
    margin-top: 50px;
}
.Wishlist-Nav-Tabs:hover {
    cursor: pointer;
}

.Wishlist-Nav-Tabs>nav-item>.nav-link {
    background-color: rgba(125, 92, 190, 0.3) !important;
    color: white !important;;
  }
  
  .active.nav-link {
    background-color: #7d5cbe !important;
    color: white !important;
  }


.Wishlist-Nav-Tabs.nav.nav-tabs{
    margin-bottom: 24px; 
}