.App {
  background-color: #faf9fa;
  min-height: 100vh;
}

.App-logo {
  display: inline-block;
  width: 33px;
  height: 42px;
  float: left;
}

.App-title {
  display: inline-block; 
  width: 95px; 
  height: 20px; 
  font-family: CircularStd; 
  font-size: 16px; 
  font-weight: bold; 
  font-style: normal;
  font-stretch: normal; 
  line-height: normal; 
  letter-spacing: normal; 
  color: #292929;
}

.App-subtitle {
  width: 227px; 
  height: 14px; 
  font-family: Chalkduster; 
  font-size: 9px; 
  font-weight: normal; 
  font-style: normal; 
  font-stretch: normal; 
  line-height: normal; 
  letter-spacing: normal; 
  color: #292929;
  margin: 0px 0px 0px 19px;
  position: absolute;
  top: 30px;
  left: 30px;
}

.App-see-more {
  background-color: #5f5f5f; 
  color: white; 
  text-align: center; 
  font-weight: bold; 
  font-size: 16px; 
  font-family: HelveticaNeue;
  height: 42px; 
  padding-left: 5px;
}

.App-see-more-text {
  padding-top: 9px;
}

.App-auth-button {
  float: right;
}

@media (min-width: 720px) {
  .App-wishlist {
    margin-top: -33px; 
  }
  .App-profile {
    margin-top: -33px; 
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .App-wishlist {
    margin-top: 20px;
  }
}
