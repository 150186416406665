.main-nav-link {
  margin-bottom: 0px;
  width: 248px;
  height: 19px;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #d3cae8;
}

.nav-link {
  font-family: Roboto, sans-serif;
}

.navbar-light .navbar-toggler {
  border: none !important;
  border-radius: 0px;
  box-shadow: 0px !important;
}

.navbar-toggler-icon {
  background-image: url("./../../hamburger-menu2x.png") !important;
  height: 14px !important;
  width: 23px !important;
}

.main-nav {
  margin-bottom: 8px !important;
}


.nav-profile-image {
  margin-top: 6px;
  border-radius: 50%;
  height: 23px;
  width: 23px;
  border: 1px white solid;
  box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.3);
  float: right;
}

#panda-nav {
  /* padding-left: 5%;
  padding-right: 5%; */
  background-image: linear-gradient(90deg, #4a377c, #7d5cbe);
  
}

.navbar-toggler {
  box-shadow: none;
  margin-top: 4px !important;
}

@media (min-width:320px) {
  .logo-image {
    width: 110px;
    height: 32px;
    position: relative;
    margin-left: 5px; 
  }
}
/*
@media (min-width: 320px) and (max-width: 480px) {
  #panda-nav {
    height: 96px;
  }
  .logo-image {
    width: 164px;
    height: 47px;
    position: relative;
    top: 10px;
    margin-left: -10px; 
  }
  .navbar-toggler {
    position: absolute; */
    /* top: 50px;
    right: 24px; */
  /* } */

  /* .navbar-toggler-icon{
    width: 23px;

    height: 14px;
    
    top: -8px;
    position: absolute;
    float: right;
  }

  button.navbar-toggler{
    position: absolute;
    right: 24px;
    top: 53px;
  }
} */

@media (min-width: 481px) {
  #panda-nav {
    height: 170px;
  }
  .navbar-brand {
    margin-left: 9%;
  }
  .navbar-collapse {
    margin-right: 10%;
  }
  .logo-image {
    width: 164px;
    height: 47px;
    position: relative;
    top: 10px;
  }
  button.navbar-toggler{
    position: absolute;
    right: 24px;
    top: 53px;
  }
}

.auth-modal {
  max-width: 582px !important;
  margin-top: 100px !important;
}

.auth-modal .close {
  position: absolute;
  top: 22px;
  right: 22px;
  color: #7d5cbe;
}

.auth-modal .modal-header {
  margin-left: auto;
  margin-right: auto;
  border-bottom: 0px !important;
}

.auth-modal .modal-title {
  font-size: 24px;
  color: #292929;
  font-family: Roboto, sans-serif;
  width: 259px;
  height: 74px;
}

.auth-modal .modal-body {
  padding-bottom: 0px;
  margin-left: auto;
  margin-right: auto;
}
.twitch-auth-button {
  font-family: Roboto, sans-serif;
  font-size: 20px;
  border-radius: 25px !important;
  margin-bottom: 20px;
  width: 100%;
  color: white;
  background-color: #4a377c !important;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
}

.mixer-auth-button {
  font-family: Roboto, sans-serif;
  font-size: 20px;
  border-radius: 25px !important;
  margin-bottom: 40px;
  width: 100%;
  color: white;
  background-color: #1654c6 !important;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
}

.modal-footer {
  justify-content: center !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  border-top: 0px !important;
}
.cancel-auth-button {
  font-family: Roboto, sans-serif;
  font-size: 16px;
  color: #7d5cbe;
  cursor: pointer;
  margin-bottom: 46px;
}

.twitch-auth-logo {
  width: 36px;
  height: 37px;
  margin-right: 10px;
}

.mixer-auth-logo {
  width: 36px;
  height: 37px;
  margin-right: 10px;
}