.GiftRegistry-addItem {
  background-color: #302254;
  color: white;
  text-align: center;
  border-radius: 9px;
  padding: 16px; 
  font-size: 20px; 
  font-weight: 600; 
  margin-bottom: 16px;
  -webkit-box-shadow: 0px 26px 30px -18px rgba(0,0,0,0.3);
  -moz-box-shadow: 0px 26px 30px -18px rgba(0,0,0,0.3);
  box-shadow: 0px 26px 30px -18px rgba(0,0,0,0.3);
}

.GiftRegistry-Loading {
  background-color: #302254;
  color: white;
  text-align: center;
  height: 160px;
  border-radius: 9px;
  margin-bottom: 16px;
  -webkit-box-shadow: 0px 26px 30px -18px rgba(0,0,0,0.3);
  -moz-box-shadow: 0px 26px 30px -18px rgba(0,0,0,0.3);
  box-shadow: 0px 26px 30px -18px rgba(0,0,0,0.3);
}

.GiftRegistry-AddItemBox {
  background-color: #302254;
  color: white;
  text-align: center;
  border-radius: 9px;
  padding-top: 9px;
  padding-bottom: 12px;
  margin-bottom: 16px;
  -webkit-box-shadow: 0px 26px 30px -18px rgba(0,0,0,0.3);
  -moz-box-shadow: 0px 26px 30px -18px rgba(0,0,0,0.3);
  box-shadow: 0px 26px 30px -18px rgba(0,0,0,0.3);
}

.GiftRegistry-no-items {
  display: block;
  text-align: center;
}

.GiftRegistry-no-items-guest {
  display: block;
  text-align: center;
  margin-top: 93px;
}

.Wishlist-messageInput {
  width: 96%;
  margin-left: 2%;
}

.Wishlist-addInstructions {
  color: white;
  font-family: Roboto, sans-serif;
  font-size: 20px;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left; 
  padding-bottom: 10px;
}

.Wishlist-itemInput {
  width: 75%;
  font-size:14px;
}

.Wishlist-itemInput::-webkit-input-placeholder {
  font-family: Roboto, sans-serif;
}

.Wishlist-itemInput:-ms-input-placeholder {
  font-family: Roboto, sans-serif;
}

.Wishlist-itemInput:-moz-placeholder {
  font-family: Roboto, sans-serif;
}

.Wishlist-itemInput::-moz-placeholder {
  font-family: Roboto, sans-serif;
}

.confirm-add-button {
    width: 100%;
    height: 48px;
    border-radius: 4px;
    background-color: #7d5cbe !important;
    box-shadow: 0px 0px 0px 0px !important;
    border-radius: 0px 3px 3px 0px !important;
    margin-top: 0px;
}

.button-div{
  padding:0 !important;
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 51px;
  height: 51px;
  margin: 6px;
  border: 6px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media (min-width: 720px) {
  .GiftRegistry-addItem  {
    min-height: 132px;
  }
  .Wishlist-addInstructions {
    width: 330px;
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .GiftRegistry-addItem  {
    margin-top: 45px;
  }
}
