.Item-pic {
  max-width: 100% !important;
  max-height: 100px !important;
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}

.Item-pic-col{
  display: flex;
  justify-content: center; /* align horizontal */
  align-items: center; /* align vertical */
  padding-right: 0px !important;
  margin-left: 15px;
}

.dollar-sign {
  font-size: 12px;
  vertical-align: super;
  margin-right: 2px;
  font-weight: 400;
  line-height: 27px;
}

.item-price-cents {
  font-size: 12px;
  vertical-align: super;
  margin-left: 1px;
  font-weight: 400;
  /* line-height: 27px; */
}

.showOverlay{
  position: absolute;
  z-index: 1;
  height: 144px; 
  width: 100%; 
  background-color: #7d5cbe; 
  opacity: .95; 
  color: white;
  cursor: pointer;
  font-family: Roboto, sans-serif; 
  font-size: 20px; 
  border-radius: 7px;
  display: table; 
}

.child-text{
  text-align: center; 
  display: table-cell;
  vertical-align: middle;
}

.hideOverlay{
  display: none;
}

.item-li {
  position: relative; 
  list-style-type: none;
  background-color: white;
  border-radius: 7px;
  border: 0.5px solid #e7e6eb;
  margin-bottom: 1.25rem;
  /* border: solid 0px #e3e0ea; */
  -webkit-box-shadow: 0px 26px 30px -18px rgba(0,0,0,0.3);
  -moz-box-shadow: 0px 26px 30px -18px rgba(0,0,0,0.3);
  box-shadow: 0px 26px 30px -18px rgba(0,0,0,0.3);
}

.item-li:hover   {
  box-shadow: 0 50px 50px -40px rgba(0,0,0,.2);
  transform: scale(1.02);
  z-index:999999;
  transition-duration: 0.3s;
}

.item-li-purchased{
  position: relative; 
  list-style-type: none;
  border-radius: 7px;
  height: 144px;
  margin-bottom: 1.25rem;
  border: solid 0px #e3e0ea;
  -webkit-box-shadow: 0px 26px 30px -18px rgba(0,0,0,0.3);
  -moz-box-shadow: 0px 26px 30px -18px rgba(0,0,0,0.3);
  box-shadow: 0px 26px 30px -18px rgba(0,0,0,0.3);
  padding: 12px;
  text-shadow: 0 1px 1px rgba(255, 254, 164, 0.5);

      color: #836a38;
    background: linear-gradient(-71deg, #ffefa5, #ffefa5, #cea936, #ffefa5);
    background-size:200% 200%;
    -webkit-animation: Gradient 4s ease;
    -moz-animation: Gradient 4s ease;
    animation: Gradient 4s ease;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    animation-duration: 2s;
    -webkit-animation-duration: 8s;
}

@-webkit-keyframes Gradient {
  0% {
    background-position: 0% 50%
  }
  50% {
    background-position: 100% 50%
  }
  100% {
    background-position: 0% 50%
  }
}

@-moz-keyframes Gradient {
  0% {
    background-position: 0% 50%
  }
  50% {
    background-position: 100% 50%
  }
  100% {
    background-position: 0% 50%
  }
}

@keyframes Gradient {
  0% {
    background-position: 0% 50%
  }
  50% {
    background-position: 100% 50%
  }
  100% {
    background-position: 0% 50%
  }
}

.item-li-purchased:hover   {
  box-shadow: 0 50px 50px -40px rgba(0,0,0,.2);
  transform: scale(1.02);
  z-index:999999;
  transition-duration: 0.3s;
}

.item-name {
  width: 100%;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  padding-top: 10px;
  margin-top: 4px;
  margin-bottom: 4px;
}
.item-price {
  font-family: Roboto, sans-serif;
  font-size: 24px;
  font-weight: 800;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  color: #7d5cbe;
  display: inline;
  margin-right: 1.5rem !important;
}

.item-user {
  margin-right: 5px;
  display: inline;
  border-radius: 50%;
  border: 1px white solid;
  box-shadow: 0px 2px 2px 0px #999;
}

.item-quote {
  margin-left: 10%;
  margin-right: -6%;
  justify-content: flex-end;
  display: flex;
}

.tooltip-div{
  padding-top: 5px; 
  color: #555; 
  font-size: 12px;
  line-height: 1.5em; 
}

.img-container{
  /* position: absolute;
  right: 0px;
  bottom: 24%; */
  align-self: flex-end;
  margin-bottom: 1.25rem;
}

.trash-icon {
  float: right;
  margin-right: 4px;
  color: #d2d2d2;
  font-size: 22px;
  margin-top: 58px;
  cursor: pointer;
  position: absolute;
  right: 14px;
}

.bubble {
    right: 7px;
    background-color: #e7e6eb;
    border-radius: 0.5rem 0.5rem 0 0.5rem;
    box-sizing: border-box;
    clear: both;
    float: left;
    margin-bottom: 20px;
    padding: 0.5rem 1rem 0.5rem 1rem;
    position: relative;
    width: auto;
    max-width: 100%;
    word-wrap: break-word;
    color: black;
    /* margin-right: 10px; */
    font-size: 0.75rem;
    font-family: Roboto, sans-serif; 
  }

  .purchased-item-pic{
    height: 64px; 
    width: 64px; 
    border-radius: 50%;
  }

  .purchased-item-price{
    font-size: 10px;
    font-weight: 700;  
    width: auto;
    padding: 4px; 
    border-radius: 4px;
    color: white; 
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    background-image: linear-gradient(142deg, #39db49, #43903b);
    bottom: 0px; 
    right: 10px; 
    position: absolute; 
  }

  .purchased-item-pic-container{
    text-align: center; 
    position: relative; 
  }

  .purchased-item-name{
    font-size: 12px; 
    color: #836a38;
    font-weight: 700; 
    height: 18px; 
    overflow: hidden;
    text-shadow: 0 1px 1px rgba(255, 254, 164, 0.5);
  }

  .purchased-item-buyer-username{
    font-size: 14px; 
    font-weight: 600; 
    text-align: center;
    text-decoration: underline; 
    margin-left: 5px; 
  }

  .purchased-item-buyer-image{
    border-radius: 50%; 
    height: 24px; 
    width: 24px; 
  }

  .purchased-item-buyer-text-anonymous{
    margin-left: 3px;
  }

  .purchased-item-buyer-text{
    margin-left: 3px;
    color: #7d5cbe;
    text-decoration: underline; 
  }

.btn-wishpanda-purple:focus,
.btn-wishpanda-purple.focus {
    box-shadow: 0 0 0 .2rem rgba(91, 194, 194, 0.5)
}

.btn-wishpanda-purple.disabled,
.btn-wishpanda-purple:disabled {
    color: #212529;
    background-color: #7cc;
    border-color: #5bc2c2
}

.btn-wishpanda-purple:not(:disabled):not(.disabled):active,
.btn-wishpanda-purple:not(:disabled):not(.disabled).active,
.show>.btn-wishpanda-purple.dropdown-toggle {
    color: #212529;
    background-color: #9cdada;
    border-color: #2e7c7c
}

.btn-wishpanda-purple:not(:disabled):not(.disabled):active:focus,
.btn-wishpanda-purple:not(:disabled):not(.disabled).active:focus,
.show>.btn-wishpanda-purple.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem rgba(91, 194, 194, 0.5)
}

.btn-outline-primary {
    color: #7cc;
    background-color: transparent;
    background-image: none;
    border-color: #7cc
}

.btn-outline-primary:hover {
    color: #222;
    background-color: #8ad3d3;
    border-color: #7cc
}

.item-price-row {
  height: 38px;
}

.show-purchase-modal-button{
  position: relative; 
  top: -9px; 
  left: -10px; 
  border-radius: 4px; 
  width: 100px; 
  background-color: #7d5cbe; 
  color: white; 
  cursor: pointer; 
  font-size: 12px;
  margin-top: 10px;
  vertical-align: middle;
  height: 28px;
  line-height: inherit;
}
