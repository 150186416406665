
@media (min-width: 320px) and (max-width: 480px) {
  .extended-header{
    background-image: linear-gradient(90deg, #4a377c, #7d5cbe);
    font-family: Roboto, sans-serif;
    font-size: 48px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
  }
  
  .faq-questions:hover{
    cursor: pointer;
  }
  
  .faq-questions{
    font-family: Roboto, sans-serif;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.54;
    letter-spacing: normal;
    color: #2b2b2b;
    font-weight: bold;
    padding-top:24px;
  }
  
  .faq-answer{
    font-family: Roboto, sans-serif;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.54;
    letter-spacing: normal;
    color: #2b2b2b;
    padding-top:24px;
    padding-bottom:24px;
  }
  
  .faq-body{
    background-color: #ffffff;
  }
  
  ul.custom-bullet {
    left: 0;
    list-style-image: url('../../list-chevron.svg');
    padding-right:4px;
  }

  .extended-subtitle{
    font-size: 16px;
    padding-left: 20px;
    padding-bottom:30px;

  }

  .extended-title{
    padding-left: 20px;
    padding-bottom: 2px
  }
  
}

@media (min-width: 720px) {
  .extended-header{
    background-image: linear-gradient(90deg, #4a377c, #7d5cbe);
    font-family: Roboto, sans-serif;
    font-size: 48px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
    padding-left:200px;

  }
  
  .faq-questions:hover{
    cursor: pointer;
  }
  
  .faq-questions{
    font-family: Roboto, sans-serif;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.54;
    letter-spacing: normal;
    color: #2b2b2b;
    font-weight: bold;
    padding-top:24px;
  }
  
  .faq-answer{
    width: 580px;
    height: 48px;
    font-family: Roboto, sans-serif;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.54;
    letter-spacing: normal;
    color: #2b2b2b;
    padding-top:24px;
    padding-bottom:24px;
  }
  
  .faq-body{
    /* background-color: #ffffff; */
    margin-left: 20%;
    margin-right: 20%;
  }
  
  ul.custom-bullet {
    left: 0;
    list-style-image: url('../../list-chevron.svg');
    padding-right:4px;
  }

  .extended-subtitle{
    font-size: 16px;
    padding-left:198px;
    padding-bottom:30px;

  }

  .extended-title{
    padding-left:195px;
    padding-bottom: 2px
  }
}