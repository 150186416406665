.card-padding{
    padding: 1.25rem;    
}

.p3-input{
    width: 100%; 
}

.brand-submit{
    float: right; 
}