html {
  height: 100%;
  margin: auto;
  background-image: -webkit-gradient(linear, left top, right top, from(#7d5cbe),  to(#4a377c));
  background-image: -webkit-linear-gradient(left, #7d5cbe,  #4a377c);
  background-image: linear-gradient(90deg, #7d5cbe,  #4a377c);
}
body {
  min-height: 100%;
  font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
}

#root {
  min-height: 100%;
}

.nav-link{
  font-family: HelveticaNeue;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #d3cae8 !important;
}

.navbar-light .navbar-nav .nav-link {
  color:#d3cae8 !important;
}
.navbar-light .navbar-nav .nav-link:hover {
  cursor: pointer;
}

/* * {
    border: 1px solid #f00 !important;
  } */
.main-nav-link {
  margin-bottom: 0px;
  width: 248px;
  height: 19px;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #d3cae8;
}

.nav-link {
  font-family: Roboto, sans-serif;
}

.navbar-light .navbar-toggler {
  border: none !important;
  border-radius: 0px;
  box-shadow: 0px !important;
}

.navbar-toggler-icon {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAC4AAAAcCAYAAAAeC42RAAAAAXNSR0IArs4c6QAAAHpJREFUWAnt1sEJwCAQRNE1daShNGJRaS/2sdkVvOlRYeALS8RDGJ6BTHH3x8zemDtGYbUIWUsE/2KjEnrAtmvs1J4ZvMYkv8rqn4pKWHIigAACCMwF8pdPV5nbbDmlq2xhXbyUrrKA4RgBBBBQEqCrHL4tuspJ8N5VfjuUJkHH2Cy5AAAAAElFTkSuQmCC) !important;
  height: 14px !important;
  width: 23px !important;
}

.main-nav {
  margin-bottom: 8px !important;
}


.nav-profile-image {
  margin-top: 6px;
  border-radius: 50%;
  height: 23px;
  width: 23px;
  border: 1px white solid;
  box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.3);
  float: right;
}

#panda-nav {
  /* padding-left: 5%;
  padding-right: 5%; */
  background-image: -webkit-gradient(linear, left top, right top, from(#4a377c), to(#7d5cbe));
  background-image: -webkit-linear-gradient(left, #4a377c, #7d5cbe);
  background-image: linear-gradient(90deg, #4a377c, #7d5cbe);
  
}

.navbar-toggler {
  box-shadow: none;
  margin-top: 4px !important;
}

@media (min-width:320px) {
  .logo-image {
    width: 110px;
    height: 32px;
    position: relative;
    margin-left: 5px; 
  }
}
/*
@media (min-width: 320px) and (max-width: 480px) {
  #panda-nav {
    height: 96px;
  }
  .logo-image {
    width: 164px;
    height: 47px;
    position: relative;
    top: 10px;
    margin-left: -10px; 
  }
  .navbar-toggler {
    position: absolute; */
    /* top: 50px;
    right: 24px; */
  /* } */

  /* .navbar-toggler-icon{
    width: 23px;

    height: 14px;
    
    top: -8px;
    position: absolute;
    float: right;
  }

  button.navbar-toggler{
    position: absolute;
    right: 24px;
    top: 53px;
  }
} */

@media (min-width: 481px) {
  #panda-nav {
    height: 170px;
  }
  .navbar-brand {
    margin-left: 9%;
  }
  .navbar-collapse {
    margin-right: 10%;
  }
  .logo-image {
    width: 164px;
    height: 47px;
    position: relative;
    top: 10px;
  }
  button.navbar-toggler{
    position: absolute;
    right: 24px;
    top: 53px;
  }
}

.auth-modal {
  max-width: 582px !important;
  margin-top: 100px !important;
}

.auth-modal .close {
  position: absolute;
  top: 22px;
  right: 22px;
  color: #7d5cbe;
}

.auth-modal .modal-header {
  margin-left: auto;
  margin-right: auto;
  border-bottom: 0px !important;
}

.auth-modal .modal-title {
  font-size: 24px;
  color: #292929;
  font-family: Roboto, sans-serif;
  width: 259px;
  height: 74px;
}

.auth-modal .modal-body {
  padding-bottom: 0px;
  margin-left: auto;
  margin-right: auto;
}
.twitch-auth-button {
  font-family: Roboto, sans-serif;
  font-size: 20px;
  border-radius: 25px !important;
  margin-bottom: 20px;
  width: 100%;
  color: white;
  background-color: #4a377c !important;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
}

.mixer-auth-button {
  font-family: Roboto, sans-serif;
  font-size: 20px;
  border-radius: 25px !important;
  margin-bottom: 40px;
  width: 100%;
  color: white;
  background-color: #1654c6 !important;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
}

.modal-footer {
  justify-content: center !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  border-top: 0px !important;
}
.cancel-auth-button {
  font-family: Roboto, sans-serif;
  font-size: 16px;
  color: #7d5cbe;
  cursor: pointer;
  margin-bottom: 46px;
}

.twitch-auth-logo {
  width: 36px;
  height: 37px;
  margin-right: 10px;
}

.mixer-auth-logo {
  width: 36px;
  height: 37px;
  margin-right: 10px;
}

@media (min-width: 320px) and (max-width: 480px) {
    .Profile-PandaLink-Head {
        margin-top: 20px !important;
    }
}
@media (min-width: 720px) {
    .Profile-PandaLink-Head {
        margin-top: 30px !important;
    }
    .Profile-Streamlab-Button {
      margin-top: 10px !important;
    }
}
.Profile-Image {
  width: 90px;
  margin-top: 8px;
  border: 5px solid white;
  border-radius: 500px;
  -webkit-border-radius: 500px;
  -moz-border-radius: 500px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
}

.Profile-fontawesome {
  color: #9f6d9f;
  font-size: 20px;
}

.Profile-PandaLink-Head {
    font-family: Roboto, sans-serif;
    font-weight: bold;
    font-size: 16px;
}

.Profile-Streamlab-Button {
  font-family: Roboto, sans-serif;
  font-weight: bold;
  font-size: 16px;
}

.Profile-PandaLink-Desc {
    font-family: Roboto, sans-serif;
    font-size: 12px;
}
.Profile-PandaLink-Box {
    margin-top: 10px;
    border-radius: 6px;
}
.Profile-Streamlabs-Link {
  width: 100%;
  height: 48px;
  border-radius: 4px;
  background-color: #32C3A2 !important;
  box-shadow: 0px 0px 0px 0px !important;
  border: 0px !important;
  margin-bottom: 30px;
  margin-top: 14px;
  padding: 0 0 0 0 !important;
}
.Profile-Community-ID-Title {
    font-family: Roboto, sans-serif;
    font-size: 16px;
    font-weight: bold;
    margin-top: 30px !important;
}
.Profile-Community-ID-Desc {
    font-family: Roboto, sans-serif;
    font-size: 12px;
}
.Profile-Bio-Title {
    font-family: Roboto, sans-serif;
    font-size: 16px;
    font-weight: bold;
    margin-top: 28px !important;
}
.Profile-Bio-Div {
    padding-top: 14px;
    padding-bottom: 14px;
    font-family: Roboto, sans-serif;
    font-size: 14px;
}
.Profile-Nav-Tabs {
    margin-top: 50px;
}
.Profile-Nav-Tabs:hover {
    cursor: pointer;
}
.Profile-title {
    font-size: 28px;
    font-family: Roboto, sans-serif;
    margin-top: 8px;
}
.Profile-Bio-Div-Fan {
    font-family: Roboto, sans-serif;
    font-size: 14px;
    margin-top: 37px;
}
.Profile-title-username {
    color: #4a377c;
    font-weight: bold;
}
.Profile-Account-Detail {
    font-family: Roboto, sans-serif;
    font-size: 14px;
    padding-bottom: 3px;
}
.Profile-Account-URL {
  font-family: Roboto, sans-serif;
  font-size: 14px;
  color: #7d5cbe;
}
img.profile-picture{
    max-width: 65px;
    border-radius: 50%;
    margin-bottom: 20px;
}
.profile-area {
    float: right;
}
.panel-body h3 {
    margin-top: 0;
}

#wishpanda-link{
    background-color: #efecef;
    border: 0px; 
    width: 100%;
    height: 50px;
    font-size: 16px;
    font-weight: 300;
    border-radius: 6px;
    box-shadow: 0px 0px 0px 0px;
    margin-top: 8px;
}

#wishpanda-link > span {
    color: #7d5cbe;
    background-repeat:no-repeat;
    border: none;
    cursor:pointer;
    overflow: hidden;  
    background-color: #efecef;
    width: 100%;
    font-family: Roboto, sans-serif;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.69;
    letter-spacing: normal;
    text-align: center;
    color: #7d5cbe;
    display: block;
    text-overflow: ellipsis;
    text-transform: lowercase;
}

.Profile-badge-button {
  width: 100%;
  height: 48px;
  border-radius: 4px;
  background-color: #7d5cbe !important;
  box-shadow: 0px 0px 0px 0px !important;
  border: 0px !important;
  margin-bottom: 30px;
  margin-top: 14px;
}

.Profile-Address-Row {
  margin-top: 24px;
}
.Profile-Address-Label {
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: .25rem;
  color: #2b2b2b;
}
.Profile-Address-Input {
  box-shadow: none;
}
.Profile-Address-Input::-webkit-input-placeholder {
  color: #c3c3c3 !important;
}
.Profile-Address-Input::-moz-placeholder {
  color: #c3c3c3 !important;
}
.Profile-Address-Input::-ms-input-placeholder {
  color: #c3c3c3 !important;
}
.Profile-Address-Input::placeholder {
  color: #c3c3c3 !important;
}
.saveChangesButton{
  width: 100%;
  height: 48px;
  border-radius: 4px;
  background-color: #7d5cbe !important;
  box-shadow: 0px 0px 0px 0px !important;
  border: 0px !important;
  margin-bottom: 80px;
}

.Profile-Nav-Tabs>nav-item>.nav-link {
  background-color: rgba(125, 92, 190, 0.3) !important;
  color: white !important;;
}

.active.nav-link {
  background-color: #7d5cbe !important;
  color: white !important;
}

.calendar {
    font-size:16px;
}

.panda-link-icon{
    font-size:15px;
}
.Item-pic {
  max-width: 100% !important;
  max-height: 100px !important;
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}

.Item-pic-col{
  display: flex;
  justify-content: center; /* align horizontal */
  align-items: center; /* align vertical */
  padding-right: 0px !important;
  margin-left: 15px;
}

.dollar-sign {
  font-size: 12px;
  vertical-align: super;
  margin-right: 2px;
  font-weight: 400;
  line-height: 27px;
}

.item-price-cents {
  font-size: 12px;
  vertical-align: super;
  margin-left: 1px;
  font-weight: 400;
  /* line-height: 27px; */
}

.showOverlay{
  position: absolute;
  z-index: 1;
  height: 144px; 
  width: 100%; 
  background-color: #7d5cbe; 
  opacity: .95; 
  color: white;
  cursor: pointer;
  font-family: Roboto, sans-serif; 
  font-size: 20px; 
  border-radius: 7px;
  display: table; 
}

.child-text{
  text-align: center; 
  display: table-cell;
  vertical-align: middle;
}

.hideOverlay{
  display: none;
}

.item-li {
  position: relative; 
  list-style-type: none;
  background-color: white;
  border-radius: 7px;
  border: 0.5px solid #e7e6eb;
  margin-bottom: 1.25rem;
  /* border: solid 0px #e3e0ea; */
  box-shadow: 0px 26px 30px -18px rgba(0,0,0,0.3);
}

.item-li:hover   {
  box-shadow: 0 50px 50px -40px rgba(0,0,0,.2);
  -webkit-transform: scale(1.02);
          transform: scale(1.02);
  z-index:999999;
  -webkit-transition-duration: 0.3s;
          transition-duration: 0.3s;
}

.item-li-purchased{
  position: relative; 
  list-style-type: none;
  border-radius: 7px;
  height: 144px;
  margin-bottom: 1.25rem;
  border: solid 0px #e3e0ea;
  box-shadow: 0px 26px 30px -18px rgba(0,0,0,0.3);
  padding: 12px;
  text-shadow: 0 1px 1px rgba(255, 254, 164, 0.5);

      color: #836a38;
    background: -webkit-linear-gradient(161deg, #ffefa5, #ffefa5, #cea936, #ffefa5);
    background: linear-gradient(-71deg, #ffefa5, #ffefa5, #cea936, #ffefa5);
    background-size:200% 200%;
    -webkit-animation: Gradient 4s ease;
    animation: Gradient 4s ease;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    animation-duration: 2s;
    -webkit-animation-duration: 8s;
}

@-webkit-keyframes Gradient {
  0% {
    background-position: 0% 50%
  }
  50% {
    background-position: 100% 50%
  }
  100% {
    background-position: 0% 50%
  }
}

@keyframes Gradient {
  0% {
    background-position: 0% 50%
  }
  50% {
    background-position: 100% 50%
  }
  100% {
    background-position: 0% 50%
  }
}

.item-li-purchased:hover   {
  box-shadow: 0 50px 50px -40px rgba(0,0,0,.2);
  -webkit-transform: scale(1.02);
          transform: scale(1.02);
  z-index:999999;
  -webkit-transition-duration: 0.3s;
          transition-duration: 0.3s;
}

.item-name {
  width: 100%;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  padding-top: 10px;
  margin-top: 4px;
  margin-bottom: 4px;
}
.item-price {
  font-family: Roboto, sans-serif;
  font-size: 24px;
  font-weight: 800;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  color: #7d5cbe;
  display: inline;
  margin-right: 1.5rem !important;
}

.item-user {
  margin-right: 5px;
  display: inline;
  border-radius: 50%;
  border: 1px white solid;
  box-shadow: 0px 2px 2px 0px #999;
}

.item-quote {
  margin-left: 10%;
  margin-right: -6%;
  justify-content: flex-end;
  display: flex;
}

.tooltip-div{
  padding-top: 5px; 
  color: #555; 
  font-size: 12px;
  line-height: 1.5em; 
}

.img-container{
  /* position: absolute;
  right: 0px;
  bottom: 24%; */
  align-self: flex-end;
  margin-bottom: 1.25rem;
}

.trash-icon {
  float: right;
  margin-right: 4px;
  color: #d2d2d2;
  font-size: 22px;
  margin-top: 58px;
  cursor: pointer;
  position: absolute;
  right: 14px;
}

.bubble {
    right: 7px;
    background-color: #e7e6eb;
    border-radius: 0.5rem 0.5rem 0 0.5rem;
    box-sizing: border-box;
    clear: both;
    float: left;
    margin-bottom: 20px;
    padding: 0.5rem 1rem 0.5rem 1rem;
    position: relative;
    width: auto;
    max-width: 100%;
    word-wrap: break-word;
    color: black;
    /* margin-right: 10px; */
    font-size: 0.75rem;
    font-family: Roboto, sans-serif; 
  }

  .purchased-item-pic{
    height: 64px; 
    width: 64px; 
    border-radius: 50%;
  }

  .purchased-item-price{
    font-size: 10px;
    font-weight: 700;  
    width: auto;
    padding: 4px; 
    border-radius: 4px;
    color: white; 
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    background-image: -webkit-linear-gradient(308deg, #39db49, #43903b);
    background-image: linear-gradient(142deg, #39db49, #43903b);
    bottom: 0px; 
    right: 10px; 
    position: absolute; 
  }

  .purchased-item-pic-container{
    text-align: center; 
    position: relative; 
  }

  .purchased-item-name{
    font-size: 12px; 
    color: #836a38;
    font-weight: 700; 
    height: 18px; 
    overflow: hidden;
    text-shadow: 0 1px 1px rgba(255, 254, 164, 0.5);
  }

  .purchased-item-buyer-username{
    font-size: 14px; 
    font-weight: 600; 
    text-align: center;
    text-decoration: underline; 
    margin-left: 5px; 
  }

  .purchased-item-buyer-image{
    border-radius: 50%; 
    height: 24px; 
    width: 24px; 
  }

  .purchased-item-buyer-text-anonymous{
    margin-left: 3px;
  }

  .purchased-item-buyer-text{
    margin-left: 3px;
    color: #7d5cbe;
    text-decoration: underline; 
  }

.btn-wishpanda-purple:focus,
.btn-wishpanda-purple.focus {
    box-shadow: 0 0 0 .2rem rgba(91, 194, 194, 0.5)
}

.btn-wishpanda-purple.disabled,
.btn-wishpanda-purple:disabled {
    color: #212529;
    background-color: #7cc;
    border-color: #5bc2c2
}

.btn-wishpanda-purple:not(:disabled):not(.disabled):active,
.btn-wishpanda-purple:not(:disabled):not(.disabled).active,
.show>.btn-wishpanda-purple.dropdown-toggle {
    color: #212529;
    background-color: #9cdada;
    border-color: #2e7c7c
}

.btn-wishpanda-purple:not(:disabled):not(.disabled):active:focus,
.btn-wishpanda-purple:not(:disabled):not(.disabled).active:focus,
.show>.btn-wishpanda-purple.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem rgba(91, 194, 194, 0.5)
}

.btn-outline-primary {
    color: #7cc;
    background-color: transparent;
    background-image: none;
    border-color: #7cc
}

.btn-outline-primary:hover {
    color: #222;
    background-color: #8ad3d3;
    border-color: #7cc
}

.item-price-row {
  height: 38px;
}

.show-purchase-modal-button{
  position: relative; 
  top: -9px; 
  left: -10px; 
  border-radius: 4px; 
  width: 100px; 
  background-color: #7d5cbe; 
  color: white; 
  cursor: pointer; 
  font-size: 12px;
  margin-top: 10px;
  vertical-align: middle;
  height: 28px;
  line-height: inherit;
}


.center-purchase-buttons{
  margin: 0 auto; 
}

.cancel-purchase-modal{
  border-radius: 4px; 
  width: 142px; 
  height: 48px; 
  background-color: #d8d8d8; 
  color: white; 
  cursor: pointer; 
  margin: 8px; 
}


.buy-purchase-modal{
  border-radius: 4px; 
  width: 142px; 
  height: 48px; 
  background-color: #7d5cbe; 
  color: white; 
  cursor: pointer; 
  margin: 8px; 
}

  .Checkout {
    margin: 0 auto;
    max-width: 800px;
    box-sizing: border-box;
    padding: 0 5px;
  }

  label {
    color: #6b7c93;
    font-weight: 300;
    letter-spacing: 0.025em;
  }

  button {
    white-space: nowrap;
    border: 0;
    outline: 0;
    display: inline-block;
    height: 40px;
    line-height: 40px;
    padding: 0 14px;
    color: #fff;
    border-radius: 4px;
    font-size: 15px;
    font-weight: 600;
    letter-spacing: 0.025em;
    background-color: #6772e5;
    text-decoration: none;
    -webkit-transition: all 150ms ease;
    transition: all 150ms ease;
    margin-top: 10px;
  }

  form {
    margin-bottom: 40px;
    padding-bottom: 40px;
  }

  button:hover {
    color: #fff;
    cursor: pointer;
    background-color: #7795f8;
    -webkit-transform: translateY(-1px);
            transform: translateY(-1px);
    box-shadow: 0 7px 14px rgba(50, 50, 93, .10), 0 3px 6px rgba(0, 0, 0, .08);
  }

  input,
  .StripeElement {
    display: block;
    max-width: 1000px !important;
    padding: 10px 14px;
    font-size: 1em;
    font-family: 'Roboto, sans-serif';
    border: 0;
    outline: 0;
    border-radius: 4px;
    background: white;
    border: 1px solid #cccccc;
  }

  input::-webkit-input-placeholder {
    color: #aab7c4;
  }

  input::-moz-placeholder {
    color: #aab7c4;
  }

  input::-ms-input-placeholder {
    color: #aab7c4;
  }

  input::placeholder {
    color: #aab7c4;
  }

  input:focus,
  .StripeElement--focus {
    -webkit-transition: all 150ms ease;
    transition: all 150ms ease;
  }

  .StripeElement.StripeElement--empty{
    width: 400px; 
  }
  .StripeElement.StripeElement--invalid{
    width: 400px; 
  }
  .StripeElment.StripeElement--complete{
    width: 400px; 
  }
  .StripeElement{
    width: 400px !important; 
  }

  .StripeElement.IdealBankElement,
  .StripeElement.PaymentRequestButton {
    padding: 0;
  }
.close-modal{
    position: absolute;
    right: 15px;
    top: 10px;
    cursor: pointer;
    color: grey; 
}

.modal-header-title{
    text-align: center;
}

.modal-content{
    background: #f3f5f6 !important; 
}

.modal-header{
    text-align: center !important; 
    display: block !important; 
}

.modal-profile-picture-rounded{
    border-radius: 50%; 
    width: 24px; 
    height: 24px; 
    border: 2px solid #fff; 
    box-shadow: 0 5px 10px 0 rgba(17, 81, 92, 0.3);
}

.sign-in-button{
    border-radius: 4px; 
    width: 142px; 
    height: 48px; 
    background-color: #7d5cbe; 
    color: white; 
    cursor: pointer; 
    margin: 8px; 
  }
.Wishlist-Nav-Tabs {
    margin-top: 50px;
}
.Wishlist-Nav-Tabs:hover {
    cursor: pointer;
}

.Wishlist-Nav-Tabs>nav-item>.nav-link {
    background-color: rgba(125, 92, 190, 0.3) !important;
    color: white !important;;
  }
  
  .active.nav-link {
    background-color: #7d5cbe !important;
    color: white !important;
  }


.Wishlist-Nav-Tabs.nav.nav-tabs{
    margin-bottom: 24px; 
}
.GiftRegistry-addItem {
  background-color: #302254;
  color: white;
  text-align: center;
  border-radius: 9px;
  padding: 16px; 
  font-size: 20px; 
  font-weight: 600; 
  margin-bottom: 16px;
  box-shadow: 0px 26px 30px -18px rgba(0,0,0,0.3);
}

.GiftRegistry-Loading {
  background-color: #302254;
  color: white;
  text-align: center;
  height: 160px;
  border-radius: 9px;
  margin-bottom: 16px;
  box-shadow: 0px 26px 30px -18px rgba(0,0,0,0.3);
}

.GiftRegistry-AddItemBox {
  background-color: #302254;
  color: white;
  text-align: center;
  border-radius: 9px;
  padding-top: 9px;
  padding-bottom: 12px;
  margin-bottom: 16px;
  box-shadow: 0px 26px 30px -18px rgba(0,0,0,0.3);
}

.GiftRegistry-no-items {
  display: block;
  text-align: center;
}

.GiftRegistry-no-items-guest {
  display: block;
  text-align: center;
  margin-top: 93px;
}

.Wishlist-messageInput {
  width: 96%;
  margin-left: 2%;
}

.Wishlist-addInstructions {
  color: white;
  font-family: Roboto, sans-serif;
  font-size: 20px;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left; 
  padding-bottom: 10px;
}

.Wishlist-itemInput {
  width: 75%;
  font-size:14px;
}

.Wishlist-itemInput::-webkit-input-placeholder {
  font-family: Roboto, sans-serif;
}

.Wishlist-itemInput:-ms-input-placeholder {
  font-family: Roboto, sans-serif;
}

.Wishlist-itemInput:-moz-placeholder {
  font-family: Roboto, sans-serif;
}

.Wishlist-itemInput::-moz-placeholder {
  font-family: Roboto, sans-serif;
}

.confirm-add-button {
    width: 100%;
    height: 48px;
    border-radius: 4px;
    background-color: #7d5cbe !important;
    box-shadow: 0px 0px 0px 0px !important;
    border-radius: 0px 3px 3px 0px !important;
    margin-top: 0px;
}

.button-div{
  padding:0 !important;
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 51px;
  height: 51px;
  margin: 6px;
  border: 6px solid #fff;
  border-radius: 50%;
  -webkit-animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
          animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  -webkit-animation-delay: -0.45s;
          animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  -webkit-animation-delay: -0.3s;
          animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  -webkit-animation-delay: -0.15s;
          animation-delay: -0.15s;
}
@-webkit-keyframes lds-ring {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes lds-ring {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@media (min-width: 720px) {
  .GiftRegistry-addItem  {
    min-height: 132px;
  }
  .Wishlist-addInstructions {
    width: 330px;
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .GiftRegistry-addItem  {
    margin-top: 45px;
  }
}

.App {
  background-color: #faf9fa;
  min-height: 100vh;
}

.App-logo {
  display: inline-block;
  width: 33px;
  height: 42px;
  float: left;
}

.App-title {
  display: inline-block; 
  width: 95px; 
  height: 20px; 
  font-family: CircularStd; 
  font-size: 16px; 
  font-weight: bold; 
  font-style: normal;
  font-stretch: normal; 
  line-height: normal; 
  letter-spacing: normal; 
  color: #292929;
}

.App-subtitle {
  width: 227px; 
  height: 14px; 
  font-family: Chalkduster; 
  font-size: 9px; 
  font-weight: normal; 
  font-style: normal; 
  font-stretch: normal; 
  line-height: normal; 
  letter-spacing: normal; 
  color: #292929;
  margin: 0px 0px 0px 19px;
  position: absolute;
  top: 30px;
  left: 30px;
}

.App-see-more {
  background-color: #5f5f5f; 
  color: white; 
  text-align: center; 
  font-weight: bold; 
  font-size: 16px; 
  font-family: HelveticaNeue;
  height: 42px; 
  padding-left: 5px;
}

.App-see-more-text {
  padding-top: 9px;
}

.App-auth-button {
  float: right;
}

@media (min-width: 720px) {
  .App-wishlist {
    margin-top: -33px; 
  }
  .App-profile {
    margin-top: -33px; 
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .App-wishlist {
    margin-top: 20px;
  }
}


@media (min-width: 320px) and (max-width: 480px) {
  .Landing-subtitle {
    padding-top: 24px;
    padding-left: 24px;
    padding-right: 24px;
    font-family: Roboto, sans-serif;
    font-size: 36px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.28;
    letter-spacing: normal;
    background-image: -webkit-gradient(linear, left top, right top, from(#4a377c), to(#7d5cbe));
    background-image: -webkit-linear-gradient(left, #4a377c, #7d5cbe);
    background-image: linear-gradient(90deg, #4a377c, #7d5cbe);
    color: white;
  }
  .subtext{
    line-height:1.5em;
    font-family: Roboto, sans-serif; 
    padding-top: 15px; 
    font-weight: 300; 
    font-size: 1.25rem;
    font-family: Roboto, sans-serif;
  }
  p {
    margin: 0px !important;
  }

  .Landing-header {
    background-image: -webkit-gradient(linear, left top, right top, from(#4a377c), to(#7d5cbe));
    background-image: -webkit-linear-gradient(left, #4a377c, #7d5cbe);
    background-image: linear-gradient(90deg, #4a377c, #7d5cbe);
    text-align: center;
    overflow: hidden;
  }

  .Landing-signup {
    padding-top: 40px;
  }

  .Landing-signup-cta {
    background-color: white;
    color: #7d5cbe;
    font-family: Roboto, sans-serif;
    font-size: 24px;
    font-weight: 500;
    height: 48px;
    width: 145px;
    border-radius: 4px;
    box-shadow: 0 8px 10px 0 rgba(0, 0, 0, 0.2);
    margin-top: 20px;
    text-transform: none;
    text-transform: initial;
  }

  .Landing-header-image {
    height: 100%;
    width: 100%;
    padding-left: 18px;
    padding-top: 40px;
    float: right;
  }

  .Landing-form {
      margin-bottom: 0px !important;
      padding-bottom: 0px !important;
      border: 0px solid black !important;
  }

  .Landing-confirm {
    float: right;
    background-color: #7d5cbe;
    color: white;
    height:48px;
    border-radius:4px;
  }

  .Landing-email-div {
    overflow: hidden;
    padding-right: 0.5em;
    height:48px;
  }

  .Landing-email-input {
    float: left;
    width: 100%;
  }

  .Landing-email-input::-moz-placeholder  {
    font-family: Roboto, sans-serif;
    font-size: 14px;
    padding-top: 34px !important;
  }

  .Landing-why {
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 46px;
    padding-bottom: 40px;
    font-family: Roboto, sans-serif;
    font-size: 32px;
    font-weight: bold;
    line-height: 1.84;
    text-align: center
  }

  .Landing-features {
    padding-left: 12px;
    padding-right: 12px;
    padding-bottom: 47px;
  }

  .Landing-feature-image-door {
    display: block;
    margin: 0 auto;
    width: 110px;
    height: 90px;
  }

  .Landing-feature-image-safe {
    display: block;
    margin: 0 auto;
    width: 105px;
    height: 88px;
  }

  .Landing-feature-image-list {
    display: block;
    margin: 0 auto;
    width: 90px;
    height: 90px;
  }

  .Landing-pitch {
    font-family: Roboto, sans-serif;
    font-size: 19px;
    font-weight: 700;
    margin-top: 8px !important;
    margin-bottom: 8px !important;
    width: 290px;
    height: 26px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
  }

  .Landing-pitch-body {
    width: 290px;
    height: 133px;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.49;
    letter-spacing: normal;
    color: #000000;
  }

  .Landing-signoff {
    padding-left: 24px;
    padding-right: 24px;
    background-color: #bc91ff;
    color: white;
    font-family: Roboto, sans-serif;
    font-size: 23px;
    font-weight: 500;
    text-align: center;
  }

  .Landing-video {
    display: none;
    height: 554.6px;
    width: 100%;
  }

  .Landing-last-email {
    padding-left: 24px;
    padding-right: 24px;
    padding-bottom: 53px;
    text-align: center;
    background-color: #bc91ff;
  }

  .Landing-val-props-image-small {
    width: 100%;
    margin-bottom: 50px;
    mix-blend-mode: multiply;
  }
}

/* desktop */
@media (min-width: 720px) {
  .Landing-subtitle {
    padding-left: 10%;
    padding-right: 258px;
    font-family: Roboto, sans-serif;
    font-size: 4.5vw;
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.28;
    letter-spacing: normal;
    background-image: -webkit-gradient(linear, left top, right top, from(#4a377c), to(#7d5cbe));
    background-image: -webkit-linear-gradient(left, #4a377c, #7d5cbe);
    background-image: linear-gradient(90deg, #4a377c, #7d5cbe);
    color: white;
  }
  .subtext{
    line-height:1.5em;
    font-family: Roboto, sans-serif; 
    padding-top: 15px;
    padding-right: 40%;
    font-weight: 300; 
    font-size: 2rem;
    font-family: Roboto, sans-serif;
  }
  body{
    width:100%;
    overflow:auto;
  }

  p {
    margin: 0px !important;
  }

  .Landing-signup-cta {
    background-color: white;
    color: #7d5cbe;
    font-family: Roboto, sans-serif;
    font-size: 24px;
    font-weight: 500;
    height: 64px;
    width: 178px;
    border-radius: 4px;
    box-shadow: 0 8px 10px 0 rgba(0, 0, 0, 0.2);
    margin-top: 20px;
    text-transform: none;
    text-transform: initial;
  }

  .Landing-header {
    background-image: -webkit-gradient(linear, left top, right top, from(#4a377c), to(#7d5cbe));
    background-image: -webkit-linear-gradient(left, #4a377c, #7d5cbe);
    background-image: linear-gradient(90deg, #4a377c, #7d5cbe);
    text-align: center;
    overflow: hidden;
  }

  .Landing-header-image {
    height: 301px;
    width: 667px;
    float: right;
  }

  .Landing-email-row {
    clear: right;
    padding-left: 60px;
    padding-right: 60px;
    padding-top: 43px;
    background-color: #261f39;
    color: white;
    font-size: 14px !important;
  }

  .Landing-confirm {
    float: right;
    background-color: #7d5cbe;
    color: white;
    height:48px;
    border-radius:4px;
  }

  .Landing-form {
    margin-bottom: 0px !important;
    padding-bottom: 11px !important;
    padding-top: 18px;
    padding-left:16px;
    border: 0px solid black !important;
    width: 626px;
}

  .Landing-email-div {
    overflow: hidden;
    padding-right: 0.5em;
    height:49px; 
  }

  .Landing-email-input {
    width: 100%;
    height:48px;
  }

  .Landing-email-counter {
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-weight: 500;
    color: #a1a1a1;
    padding-bottom: 40px;
  }

  .Landing-why {
    padding-left: 60px;
    padding-right: 60px;
    padding-top: 46px;
    font-family: Roboto, sans-serif;
    font-size: 45px;
    font-weight: 100;
    line-height: 1.84;
    text-align: center
  }

  .Landing-bold {
    font-weight: bold;
  }

  .Landing-features {
    padding-bottom: 47px;
    margin-top: 28px;
    margin-left: 20% !important;
    margin-right: 20% !important;
  }

  .Landing-feature-image-door {
    margin: 0 auto;
    width: 110px;
    height: 90px;
  }

  .Landing-feature-image-safe {
    margin: 0 auto;
    width: 105px;
    height: 88px;
  }

  .Landing-feature-image-list {
    margin: 0 auto;
    width: 90px;
    height: 90px;
  }
  
  .Landing-val-props-image-large {
    display: block;
    margin: 0 auto;
    margin-bottom: 40px;
    width:700px;
    height: 378px;
  }

  .Landing-pitch {
    font-family: Roboto, sans-serif;
    font-size: 19px;
    font-weight: bold;
    margin-top: 8px !important;
    margin-bottom: 22px !important;
    text-align: left;
    height: 26px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
    
  }

  .Landing-pitch-body {
    text-align: left;
    height: 133px;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.49;
    letter-spacing: normal;
    color: #000000;
  }

  .Landing-signoff {
    padding-left: 24px;
    padding-right: 24px;
    background-color: #bc91ff;
    color: white;
    font-family: Roboto, sans-serif;
    font-size: 23px;
    font-weight: 500;
    text-align: center;
  }

  .Landing-video {
    height: 554.6px;
    width: 100%;
  }

  .Landing-last-email {
    padding-left: 24px;
    padding-right: 24px;
    padding-bottom: 53px;
    text-align: center;
    background-color: #bc91ff;
  }

  .Landing-form.bottom-form{
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}

.alert {
  margin-bottom: 0px !important;
}

@media (min-width: 320px) and (max-width: 480px) {
  .extended-header{
    background-image: -webkit-gradient(linear, left top, right top, from(#4a377c), to(#7d5cbe));
    background-image: -webkit-linear-gradient(left, #4a377c, #7d5cbe);
    background-image: linear-gradient(90deg, #4a377c, #7d5cbe);
    font-family: Roboto, sans-serif;
    font-size: 48px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
  }
  
  .faq-questions:hover{
    cursor: pointer;
  }
  
  .faq-questions{
    font-family: Roboto, sans-serif;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.54;
    letter-spacing: normal;
    color: #2b2b2b;
    font-weight: bold;
    padding-top:24px;
  }
  
  .faq-answer{
    font-family: Roboto, sans-serif;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.54;
    letter-spacing: normal;
    color: #2b2b2b;
    padding-top:24px;
    padding-bottom:24px;
  }
  
  .faq-body{
    background-color: #ffffff;
  }
  
  ul.custom-bullet {
    left: 0;
    list-style-image: url(/static/media/list-chevron.80088f4f.svg);
    padding-right:4px;
  }

  .extended-subtitle{
    font-size: 16px;
    padding-left: 20px;
    padding-bottom:30px;

  }

  .extended-title{
    padding-left: 20px;
    padding-bottom: 2px
  }
  
}

@media (min-width: 720px) {
  .extended-header{
    background-image: -webkit-gradient(linear, left top, right top, from(#4a377c), to(#7d5cbe));
    background-image: -webkit-linear-gradient(left, #4a377c, #7d5cbe);
    background-image: linear-gradient(90deg, #4a377c, #7d5cbe);
    font-family: Roboto, sans-serif;
    font-size: 48px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
    padding-left:200px;

  }
  
  .faq-questions:hover{
    cursor: pointer;
  }
  
  .faq-questions{
    font-family: Roboto, sans-serif;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.54;
    letter-spacing: normal;
    color: #2b2b2b;
    font-weight: bold;
    padding-top:24px;
  }
  
  .faq-answer{
    width: 580px;
    height: 48px;
    font-family: Roboto, sans-serif;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.54;
    letter-spacing: normal;
    color: #2b2b2b;
    padding-top:24px;
    padding-bottom:24px;
  }
  
  .faq-body{
    /* background-color: #ffffff; */
    margin-left: 20%;
    margin-right: 20%;
  }
  
  ul.custom-bullet {
    left: 0;
    list-style-image: url(/static/media/list-chevron.80088f4f.svg);
    padding-right:4px;
  }

  .extended-subtitle{
    font-size: 16px;
    padding-left:198px;
    padding-bottom:30px;

  }

  .extended-title{
    padding-left:195px;
    padding-bottom: 2px
  }
}
.footer {
  background-color: #1f103e;
}

.footer-padding {
    padding-left: 24px !important;;
}

.footer-headers {
  font-weight: bold;
  line-height: 24.7px;
  color: #955aff;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  padding-top: 41px;
}

.footer-links {
  list-style: none;
  padding-left: 0;
  padding-bottom: 60px;
  width: 173px;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.54;
  letter-spacing: 0.5px;
  color: #ffffff;
}

.logo-image-footer {
  width: 160.6px;
  height: 46.6px;
}

.all-rights {
  height: 85px;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.54;
  letter-spacing: normal;
  color: #955aff;
}

.panda-link {
  color: #ffffff;
  line-height: 1.54;
}

.panda-link:hover {
  color: #d3cae8;
  text-decoration: none;
}

.brands-text-center{
    text-align: center; 
    margin: 10px; 
}

.brands-numbers{
    color: #955aff; 
    font-size: 24px; 
    font-weight: 800;
    font-family: 'Roboto', sans-serif; 
    text-align: center; 
}

.hidden{
    visibility: hidden;
}

.brand-form-card{
    margin-top: 20px; 
    padding: 15px; 
    margin-bottom: 40px; 
}

input.brand-input{
    width: 100%; 
}

.max-width{
    width: 100%; 
}

.brand-form{
    margin-bottom: 0px; 
    padding-bottom: 0px ;
}

.brand-card{
    margin-top: 20px; 
}
.card-padding{
    padding: 1.25rem;    
}

.p3-input{
    width: 100%; 
}

.brand-submit{
    float: right; 
}
